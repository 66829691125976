import _ from 'lodash';
import React, { Fragment } from 'react'
import ReactCountryFlag from "react-country-flag";
import { Dialog, Transition } from '@headlessui/react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ButtonCssClass, cn } from '@/services/Methods/normalMethods';
import localStorageCall from '@/services/Methods/localstorage.hook';
import countryListAllIsoData from '@/comman/AllStatesCodeArray';
import ButtonComponent from '@/Inputs/ButtonComponent';

const CountryFlagDropdown = ({
    countyObjects, showCountries, selectedStore, setSelectedStore, setShowCountries, pathname
}) => {

    const PREVIOUS_COUNTRY_NAME = _.find(countryListAllIsoData, { code: _.upperCase(selectedStore?.defaultCode) })?.name;
    const NEXT_COUNTRY_NAME = _.find(countryListAllIsoData, { code: _.upperCase(selectedStore?.newCode) })?.name;
    return (
        <>
            <ul className={`dropdown-menu hidden dropdown-menu-right show max-lg:mt-2 bg-[#fff] flex-col gap-[10px] lg:absolute top-[40px] right-0 min-w-[180px] rounded-[4px] p-[10px] border-[1px] border-[#591069] did-fade-in ${showCountries ? "open-country" : ""} [&.open-country]:flex`} x-placement="top-end">
                {_.map([
                    ..._.reverse(_.filter(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))),
                    ..._.reject(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))],
                    (row) => (
                        <li key={row?.code} className="block" onClick={() => {
                            setSelectedStore((prv) => ({
                                ...prv, newCode: _.lowerCase(row?.code)
                            }));
                            setShowCountries(!showCountries);
                        }}>
                            <button className="dropdown-item flex lg:text-[16px] text-sm !leading-[1]">
                                <ReactCountryFlag className="!block" countryCode={row?.code} style={{ fontSize: '1em', marginRight: '4px', lineHeight: '2em' }} svg />
                                {row?.name}
                            </button>
                        </li>
                    ))}
            </ul>
            <Transition appear show={!!(selectedStore?.defaultCode !== selectedStore?.newCode)} as={Fragment}>
                <Dialog as="div" className="relative z-[1400]" onClose={() => console.log('close')}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[rgba(0,0,0,0.8)]" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center px-[15px]">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className={cn("z-[1400] mx-0 w-full max-w-[600px] transform md:p-7 p-5 bg-[#fff]  text-left align-middle  transition-all rounded-md")}>
                                    <Dialog.Title as="h2" className="text-base/7 font-medium text-[#591069] mb-2">
                                        Change Country
                                    </Dialog.Title>
                                    <div className='flex py-2 mb-2 font-semibold border-b-2 justify-center gap-[10px] items-center'>
                                        <p>{PREVIOUS_COUNTRY_NAME}</p>
                                        <ArrowRightAltIcon />
                                        <p>{NEXT_COUNTRY_NAME}</p>
                                    </div>
                                    <div>
                                        <p>Are you sure you want to change country? Your cart will be emptied and you will be sent back to the {_.includes(pathname, 'main-enrollment') ? 'first step of Enrollment flow' : _.includes(pathname, 'products') ? "product page" :'homepage'}.</p>
                                    </div>
                                    <div className='w-full flex justify-center gap-x-[20px] mt-4'>
                                        <ButtonComponent classes="!mb-0 max-w-[200px]" onClick={() => setSelectedStore((prv) => ({ ...prv, newCode: prv?.defaultCode }))}>Cancel</ButtonComponent>
                                        <ButtonComponent classes="!mb-0 max-w-[200px]" onClick={() => {
                                            _.map(['normal', 'distributor'], (row) => {
                                                localStorageCall().removeItem(String(`${row}Products`));
                                                localStorageCall().setItem('store', selectedStore?.newCode);
                                                setSelectedStore((prv) => ({ ...prv, defaultCode: prv?.newCode }))
                                                if (_.includes(pathname, 'main-enrollment')) {
                                                    window.location.assign(`/${selectedStore?.newCode}/main-enrollment`)
                                                } else if(_.includes(pathname, 'products') || _.includes(pathname, 'checkout')){
                                                    window.location.assign(`/${selectedStore?.newCode}/products`)
                                                } else {
                                                    window.location.assign(`/${selectedStore?.newCode}`)
                                                }
                                            })
                                        }}>Ok</ButtonComponent>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default CountryFlagDropdown;