import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const ReactPaginateStyle = styled.div`
&&
  .main_pagination_section_class {
    .order_pagination {
      text-align: center;
      width: 100%;
      max-width: 100%;
      margin: 0 auto 0;
      display: flex;
      justify-content: center;
      ul {
        &.pagination {
          width: auto;
          float: left;
          justify-content: center;
          display: flex;
          background: var(--bs-green);
          margin-left: 28px;
          margin: 30px 0 0;
          padding: 7px 10px;
          border-radius: 25px;
          align-items: center;

          @media (max-width: 1199px){
            max-width: max-content;
             margin-top: 20px !important;
          }
          @media (max-width: 767px){ 
            margin-bottom: 0 !important;
            margin-top: 30px !important; 
            gap: 6px;
          }
          li {
            padding: 0px 8px;
            width: auto !important;
            height: auto !important;
            border-radius: 50%;
            margin-bottom: 0;
            &.active {
              background: #fff;
              /* color: #000 !important; */
              a {
                /* color: #000 !important; */
                font-size: 25px !important;
              @media (max-width:767px){
                font-size: 16px !important;
              }
              }
            }
            a {
              padding: 5px 0px !important;
              display: block;
              background: none;
              color: #000000;
              border: none;
              text-transform: capitalize;
              width: 100%;
              font-size: 25px !important;
              svg {
                font-size: 40px;
                position: relative;
                top: -2px;
              }
              @media (max-width:767px){
                font-size: 16px !important;
              }
            }

            &.previous,
            &.next {
              &.disabled {
                display: block !important;
                color: #dddd;
                cursor: not-allowed;
                &.svg {
                  cursor: not-allowed;
                }
              }
              /* background: #fff; */
              a {
                
                color: #000000;
                border-radius: 50%;
                height: 100%;
                width: 100%;
                padding: 8px 5px 7px 7px;
                background: transparent !important;
              }
            }
            @media (max-width:767px){
              padding: 0px 4px;
            }
          }
        }
      }
    }
    @media (max-width:767px){
      .order_pagination ul.pagination li a svg {
    font-size: 22px;
}
    }
  }
`;
const ReactpaginateComp = ({ handlePageClick, pageCount, currentPage, sortOrder }) => {
  // console.log("currentPageeeeeeeeeeeeeeeeee", currentPage);
  return (
    <ReactPaginateStyle>
      <div className="main_pagination_section_class">
        <div className="order_pagination">
          <ReactPaginate
            previousLabel={<ChevronLeft />}
            nextLabel={<ChevronRight />}
            breakLabel={"..."}
            forcePage={currentPage ? currentPage - 1 : 0}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages_pagination"}
            activeClassName={"active"}
            sortOrder={sortOrder}
          />
        </div>
      </div>
    </ReactPaginateStyle>
  );
};
export default ReactpaginateComp;