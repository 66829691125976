"use client"
import React, { useMemo, useState } from 'react';
import { Delete, ShoppingCart } from "@mui/icons-material";
import { FloatValues, storeAssigned, cn, _scrollToUp, CurrencyConverter } from '@/services/Methods/normalMethods';
import { Drawer } from '@mui/material';
import _ from 'lodash';
import { Close } from "@mui/icons-material";
import { QuantityComponent } from '@/services/Methods/commonPayload';
import Image from 'next/image';
import { notFoundImage } from '@/assets/images';
import UseCreateAccountHook from '../../hooks/CreateAccount.hooks';
import { useRouter } from 'next/navigation';
import localStorageCall from '@/services/Methods/localstorage.hook';
import ShoppingCartStyleCss from '@/styles/ShoppingCartStyleCss';
import ButtonComponent from '@/Inputs/ButtonComponent';
import ReferralPopup from './ReferralPopup';
import { getCurrencySign } from '@/services/Methods/normalMethods';

const ShoppingCartComponent = ({
    openMiniCart = false,
    setOpenMiniCart = (val) => alert(`you miss to pass setOpenMiniCart with value ${val}`),
    productData = [],
    section = 'Gsn',
    _minCartQuantityIncDec = (val) => alert(`you miss props _minCartQuantityIncDec with value ${val}`),
    _deleteProductFromCart = (val) => alert(`you miss props  _deleteProductFromCart with value ${val}`),
    _autoShipCheck = (val) => alert(`you miss props _autoShipCheck with value ${val}`),
    pathname,
    _changeFrequencyOfProducts, addToCartProducts, _minCartAndViewCartQuantityIncDec,
    ...props
}) => {
    const router = useRouter();
    const isUserLogin = localStorageCall().getItem('Token');
    const {
        openPopup, setOpenPopup, offset, setOffSet, isLoadingTrue, webAliesError, handleChange, _validateRefferalFunction,
        searcheddata, setSearcheddata
    } = UseCreateAccountHook();

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        _closeMiniCart()
    };

    function _closeMiniCart() {
        setOpenMiniCart(false);
        if (section === 'distributor') {
            const element = document.getElementById('next-button');
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "end" });
            }
        }
    }


    const subTotal = useMemo(() => {
        if (productData?.length > 0) {
            return ({
                productCount: _.map(productData, 'quantity').reduce((prv, next) => prv + next),
                productSubTotal: _.map(productData, (row) => (_autoShipCheck() ? row[section + '_autoship'] : row[section + '_' + (row?.frequency === "member" ? 'autoship' : row?.frequency)]) * +row?.quantity).reduce((prv, next) => prv + next)
            })
        }
    }, [productData]);


    const ROLE = localStorageCall().getItem('customerTypeDescr')?.role || 'normal';
    const REFFERAL_VALUES = localStorageCall().getItem('refferal_link');
    const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
    const Action = sessionStorage.getItem('action');
    const role = localStorageCall().getItem('customerTypeDescr')?.role !== 'distributor' ? 'normal' : 'distributor';

    const excludedItemCodes = [1065, 1071, 1073, 1075, 1077, 1079, 1081];
    const codess = [1595, 1611, 1574, 1576, 1526]
    const ACTIVESTEP = localStorageCall().getSingleItem('activeStep');
    return (
        <>
            <div className=" print_logo mini-root-div" onClick={() => setOpenMiniCart(true)}>
                <ShoppingCart onClick={() => setOpenMiniCart(true)} />
                {productData?.length > 0 &&
                    <span>{productData?.length} </span>
                }
            </div>
            <div className="sidebar-checkout-wrap">
                <Drawer
                    anchor={"right"}
                    open={openMiniCart}
                    onClose={toggleDrawer(false)}
                    classes={{ paper: "flayout_cart" }}
                >
                    <ShoppingCartStyleCss>
                        <div className="cart-popup-main">
                            <div className="flex relative text-center px-6">
                                <h2 className='futura_font mainAddress_option text-[24.75px] w-full block text-[#343a40] font-medium tracking-[3px] leading-[1.2]'>Your Cart</h2>
                                <div className=" absolute top-[0] left-0">
                                    <button type="button"
                                        className=""
                                        onClick={() => _closeMiniCart()}
                                    >
                                        <Close className='md:!h-[24px] !w-[16px] md:!w-[24px] !h-[16px]' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {productData?.length > 0 ? (
                            <>
                                <div className="futura_font flex flex-col justify-between min-h-[calc(100%-67px)] border-t-2 mt-[10px] pt-[15px] border-t-[#444]">
                                    <div className="">
                                        {_.map(productData, (row) => {
                                            // const checkSection = _.find(addToCartProducts?.[`${section}Products`], { id: row?.id })?.frequency;
                                            const hideFrequencyOptions = excludedItemCodes.includes(row?.id);
                                            const hideFreq = codess.includes(row?.id);
                                            const oneTime = (section !== "distributor") ? [{ title: "One-time", value: "oneTime", price: _autoShipCheck() ? row?.[`${section}_autoship`] : row?.[`${section}_oneTime`] }] : []
                                            const frequencyOptions = [
                                                ...oneTime,
                                                // Only add autoship option if the itemCode is not in the excluded list
                                                ...(!hideFrequencyOptions ? [{ title: "Subscription", value: "autoship", price: row?.[`${section}_autoship`] }] : [])
                                            ];
                                            // console.log("rowwwwwwwwwwwwwwwwwwwwwwwwww", row);
                                            return (
                                                <div key={row?.id} className="">
                                                    <div className="block border-b-[1px] border-clr mt-4">
                                                        <div className="flex flex-wrap lg:flex-row flex-col lg:items-start items-center relative">
                                                            <div className="w-full lg:max-w-[80px] max-w-[60px] lg:h-[80px] h-[60px] lg:mb-0 mb-4 bg-[#fff]">
                                                                {row?.image
                                                                    ?
                                                                    <img src={row?.image} alt="img" className='w-full h-full max-w-[130px] mx-auto object-contain mix-blend-darken' />
                                                                    :
                                                                    <Image src={notFoundImage} alt="Image 10" className="w-full h-full object-contain mix-blend-darken" />
                                                                }
                                                            </div>
                                                            <div className='flex-1 mx-auto px-[10px]  max-lg:text-center'>
                                                                <div className="block flex-1">
                                                                    <h3 className='futura_font text-[#343a40] xl:text-[21.6px] md:text-[18px] text-[16px] leading-[1.2] lg:mb-4 mb-2 font-medium'>{row?.title}</h3>
                                                                </div>

                                                                {(row?.itemCode !== "PVZSAMPLE-1US") && !row?.member && !_.includes(pathname, 'main-enrollment') &&
                                                                    < QuantityComponent {...{ row, _minCartQuantityIncDec }} />
                                                                }
                                                            </div>
                                                            <div className="block w-full lg:pl-[20px] max-lg:mt-4 lg:max-w-[120px] lg:text-right text-center">
                                                                <h2 className='futura_font block text-[#343a40]  xl:!text-[21.6px] md:!text-[18px] !text-[16px] font-semibold mb-2'>
                                                                    {getCurrencySign(_.upperCase(storeAssigned()))} <CurrencyConverter value={(_autoShipCheck() ? row[section + '_autoship'] : row[section + '_' + (row?.frequency === 'member' ? 'autoship' : row?.frequency)])} />
                                                                </h2>
                                                                {/* {(!row?.member) && */}
                                                                <div className='delete-product block' style={{ cursor: "pointer" }}>
                                                                    <span className='delete-icon block text-[#ff0000] text-[13px]' onClick={() => _deleteProductFromCart(+row?.id)}>Remove</span>
                                                                </div>
                                                                {/* } */}
                                                            </div>
                                                        </div>
                                                        {
                                                            <div className='mt-8'>
                                                                {(!hideFreq) && !row?.member && !_.includes(pathname, 'main-enrollment') && row.isEligibleForAutoOrder === true && (
                                                                    <div data-title='DELIVERY FREQUENCY'>
                                                                        <div className="block">
                                                                            <h3 className='futura_font block text-[#343a40] xl:text-[21.6px] md:text-[18px] text-[16px] lg:mb-3 mb-3 font-medium max-lg:text-center'>Your selected delivery frequency:</h3>
                                                                            <div className="flex flex-nowrap border-[1px] border-[#403f2b] relative text-center">

                                                                                {_.map(frequencyOptions, ({ title, value, price }) => (
                                                                                    <div key={title} onClick={() => _changeFrequencyOfProducts(row?.id, value)} className={cn("block cursor-pointer w-full md:p-[10px] p-1 [&:not(:last-child)]:border-r-[1px] border-[#403f2b] box-border", { 'bg-[#ccc]': (row?.frequency === value) })}>
                                                                                        <p className='block mb-1 xl:text-[18px] lg:text-[16px] md:text-[14px] text-[13px] leading-[1.25] font-light text-[#000]'>{title}</p>
                                                                                        <h4 className='futura_font block 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] text-[16px]  leading-[1.25] font-bold text-[#343a40]'>
                                                                                            {getCurrencySign(_.upperCase(storeAssigned()))}<CurrencyConverter value={price} />
                                                                                        </h4>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>

                                    <div className="">
                                        <div className="pb-[70px]">

                                            <div className="flex justify-between items-center pt-[15px] pb-[30px]">
                                                <h2 className='futura_font text-[#343a40] leading-[1.2] xl:text-[24.5px] md:text-[22px] text-[20px] font-medium'>
                                                    Subtotal ({productData?.length}Items)
                                                </h2>
                                                <h2 className='block futura_font text-[#343a40] leading-[1.2] xl:text-[24.5px] md:text-[22px] text-[20px] font-medium'>{getCurrencySign(_.upperCase(storeAssigned()))}{FloatValues(subTotal?.productSubTotal || '0.00')}</h2>
                                            </div>
                                            {!_.includes(pathname, 'distributor') ?
                                                <div className=" text-center">
                                                    <p className='mb-[20px] text-[15px] text-black '>
                                                        Shipping and tax will be calculated at checkout
                                                    </p>

                                                    {!_.includes(pathname, 'main-enrollment') && <div className="mt-8 mb-[8px]">
                                                        <ButtonComponent
                                                            classes="py-[10px] px-[25px] max-w-[205px] bg-[#000] border-[1px] border-[#000] text-[#fff] ease-in-out transition-all 0.3s hover:bg-[transparent] hover:text-[#000] hover:bg-[#fff] hover:border-[#000] rounded-[0px] uppercase !mb-0"
                                                            onClick={() => {
                                                                if (!isUserLogin) {
                                                                    if (!REFFERAL_VALUES?.webalies) {
                                                                        setOpenMiniCart(false);
                                                                        setSearcheddata('form')
                                                                        // setOpenPopup(true)
                                                                        router.push(`/${storeAssigned()}/checkout`)
                                                                    } else {
                                                                        router.push(`/${storeAssigned()}/checkout`)
                                                                        setOpenPopup(false);

                                                                    }
                                                                } else {
                                                                    setOpenMiniCart(false);
                                                                    router.push(`/${storeAssigned()}/checkout`)
                                                                }

                                                                // !isUserLogin ? !REFFERAL_VALUES?.webalies ? setOpenPopup(true) : router.push(`/${storeAssigned()}/signin`) :  setOpenMiniCart(false) router.push(`/${storeAssigned()}/checkout`)}

                                                            }}
                                                        >
                                                            <p className='text-[13px] font-normal'>Check out</p>
                                                        </ButtonComponent>
                                                    </div>}
                                                    <ButtonComponent
                                                        classes="py-[10px] px-[25px] max-w-[205px] bg-[#000] border-[1px] border-[#000] text-[#fff] ease-in-out transition-all 0.3s hover:bg-[transparent] hover:text-[#6d2a81] rounded-[0px] uppercase !mb-0 hover:text-[#000] hover:bg-[#fff] hover:border-[#000]"
                                                        onClick={() => {
                                                            setOpenMiniCart(false);
                                                            if (pathname !== `/${storeAssigned()}/products` && !_.includes(pathname, 'main-enrollment')) {
                                                                window.location.assign(`/${storeAssigned()}/products`)
                                                            }
                                                        }}
                                                    >
                                                        <p className='text-[13px] font-normal'>{ACTIVESTEP === 3 ? 'Checkout' : 'Continue shopping'}</p>
                                                    </ButtonComponent>

                                                </div>
                                                :
                                                // <div className="border-[1px] border-clr px-[20px] py-[40px] text-center">
                                                //     <ButtonComponent classes="text-center w-full max-w-[250px] mx-auto  transtion-all duration-300 ease-in-out"
                                                //         onClick={() => {
                                                //             setOpenMiniCart(false);
                                                //             const element = document.getElementById('go_next');
                                                //             if (element) {
                                                //                 element.click();
                                                //                 _scrollToUp();
                                                //             }
                                                //         }}
                                                //     >
                                                //         Checkout
                                                //     </ButtonComponent>
                                                // </div>


                                                <div className=" text-center">
                                                    {!_.includes(pathname, 'main-enrollment') && <div className="mt-8 mb-[8px]">
                                                        <ButtonComponent
                                                            classes="py-[10px] px-[25px] max-w-[205px] bg-[#6d2a81] border-[1px] border-[#6d2a81] text-[#fff] ease-in-out transition-all 0.3s hover:bg-[transparent] hover:text-[#6d2a81] rounded-[0px] uppercase !mb-0"
                                                            onClick={() => {
                                                                setOpenMiniCart(false);
                                                                // const element = document.getElementById('go_next');
                                                                // if (element) {
                                                                //     element.click();
                                                                //     _scrollToUp();
                                                                props?.setActiveStep(props?.activeStep + 1);
                                                                // 
                                                                // }
                                                            }}
                                                        >
                                                            <p className='text-[13px] font-normal'>Check out</p>
                                                        </ButtonComponent>
                                                    </div>}

                                                    <div>
                                                        <ButtonComponent
                                                            classes="text-center w-full max-w-[205px] mx-auto  transtion-all duration-300 ease-in-out uppercase !mb-0"
                                                            onClick={() => {
                                                                setOpenMiniCart(false);
                                                                // if (!_.includes(pathname, 'products')) {
                                                                //     router.push(`/${storeAssigned()}/products
                                                                // `)
                                                                // }
                                                            }}
                                                        >
                                                            <p className='text-[13px] font-normal'>Continue shopping</p>
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {/* <Divider /> */}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p style={{ textAlign: "center" }} className=" mainAddress_option py-[20px] px-[10px] futura_font text-[#343a40] tracking-[3px] leading-[1.2] text-[19.75px] ">Cart is Empty</p>
                        )}
                    </ShoppingCartStyleCss>
                </Drawer>
            </div>
            {/* <ReferralPopup section={'checkout'}  {...{ offset, setOffSet, openPopup, setOpenPopup, isLoadingTrue, webAliesError, handleChange, _validateRefferalFunction, searcheddata, setSearcheddata }} /> */}
        </>
    )
}

export default ShoppingCartComponent;