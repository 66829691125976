"use client";
import localStorageCall from '@/services/Methods/localstorage.hook';
import { MiniLoader, storeAssigned } from '@/services/Methods/normalMethods';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import NewCustomHeader from './NewCustomHeader';
import { KeyboardArrowDown, Person } from '@mui/icons-material';
import * as Sentry from "@sentry/react";
import { MyAccoutnAPI } from '@/services/Redux/Reducer/LoginSlice';
import { useDispatch } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import CountryFlagDropdown from './CountryFlagDropdown';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
const Header = ({ showCountries, setShowCountries, selectedStore, setSelectedStore, countyObjects, userData, pathname, ...props }) => {
  const isUserLogin = localStorageCall().getItem('Token');
  const router = useRouter();
  const { joinPopup, setJoinPopup } = props;
  const [showCurrencies, setShowCurrencies] = useState(false);

  const handleLogout = () => {
    localStorageCall().removeItem('Token');
    localStorageCall().removeItem('customerTypeDescr');
    localStorageCall().removeItem("refferal_link");
    localStorageCall().removeItem('partyPlanId');
    localStorageCall().removeItem('Dates');
    localStorageCall().removeItem('activeStep');
    localStorageCall().removeItem('showPopupDismissed');
    localStorageCall().removeItem('chngecountryfromdrop');
    localStorageCall().removeItem('RenewalProduct');
    localStorageCall().removeItem('TreeMoveData');

    Sentry.setUser(null);
    window.location.assign(`/${storeAssigned()}`);
  }

  const handleSignin = () => {
    // router.push(`/${storeAssigned()}/signin`)
    window.location.assign(`${process.env.NEXT_PUBLIC_ABO_URL}`);
  }

  const handleHome = () => {
    router.push(`/${storeAssigned()}`)
  }

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    document.body.classList.remove("overflow-hidden");
  };

  const dispatch = useDispatch();

  function handleBackOffice() {
    if (isUserLogin) {
      dispatch(MyAccoutnAPI())
    }
    else {
      navigate(`/${storeAssigned()}/${ROUTING_TEXT?.login_routingName}`)
    }
  };

  const RouterhandleClick = () => {
    // window.location.href = `/${storeAssigned()}/signin`
    window.location.assign(`${process.env.NEXT_PUBLIC_ABO_URL}`);
  };

  const REFFERAL_VALUES = localStorageCall().getItem("refferal_link") ? localStorageCall().getItem("refferal_link") : "";


  return (
    <>
      <NewCustomHeader {...{ open, setOpen, handleDrawerOpen, handleDrawerClose, handleLogout, handleSignin, joinPopup, setJoinPopup }} />
      <div className='langi_div relative w-full group block'>
        <div className='relative z-10 container xl:max-[1140px] mx-auto px-[15px] py-[9px] flex justify-end'>
          <button
            className="topbar-link dropdown-toggle flex items-center after:content-[''] after:border-top-[4px] after:block after:border-r-[4px] after:border-transparent after:ml-[4px]"
            data-toggle="dropdown" aria-expanded="false" onClick={() => {
              setShowCountries(!showCountries);
              setShowCurrencies(false);
            }}>
            <ReactCountryFlag svg countryCode={selectedStore?.defaultCode || 'US'} className="!block !w-[20px] !h-[20px]" />
            <KeyboardArrowDown className='fill-[#212529]' />
          </button>
          <div id="weglot_here"></div>
          <CountryFlagDropdown {...{ selectedStore, setSelectedStore, countyObjects, showCountries, setShowCountries, pathname }} />
          {showCurrencies && (
            <div className="p-[10px] langu_box absolute top-full bg-white border border-gray-300 shadow-md max-w-[180px] w-full border-[rgba(0_0_0_0.15)] rounded-[5px] mt-[-10px]">
              {[...new Set(countyObjects.map((country) => country.currency))].map((currency, index) => (
                <div
                  key={index}
                  className="p-2 px-[16px] hover:bg-gray-200 cursor-pointer"
                  onClick={() => {
                    setShowCurrencies(false);
                  }}
                >
                  {/* <span>{currency}</span> */}
                </div>
              ))}

            </div>
          )}
        </div>

      </div>
      {!_.includes(pathname, 'main-enrollment') && <header className='bg-[#000000]'>


        <div className="container 2xl:max-w-[1920px] mx-auto 2xl:p-[10px_32px] p-[15px_15px]">

          <div className='refer-name'>
            {REFFERAL_VALUES?.name && !isUserLogin && <span className='user_details-1'>You are shopping with: {REFFERAL_VALUES?.name}</span>}
          </div>
          <div className="logo_sec flex items-center justify-between">
            <div className="logo cursor-pointer xl:max-w-[267px] lg:max-w-[170px] max-w-[114px] w-full" onClick={handleHome} >
              <img className="image1 w-full" src="/images/ViagoLogo2.png" />
            </div>

            <div className="login-sec md:flex items-center lg:gap-[16px] gap-[6px] hidden">
              {
                !isUserLogin && (

                  <a href={`/${storeAssigned()}/main-enrollment`} className="leading-normal  futura_font font-normal text-black text-lg border-2 border-[#8dfa00] bg-[#8dfa00]  rounded-full py-1 px-5">Join us</a>
                )

              }

              <p onClick={() => isUserLogin ? handleBackOffice() : RouterhandleClick()} className=" cursor-pointer leading-normal futura_font font-normal  text-white text-lg border-2 border-[#ffffff] rounded-full py-1 px-5"> {isUserLogin ? 'Backoffice' : 'Sign In'}</p>
              <div className='user'>
                {isUserLogin && (
                  <>
                    {(userData?.firstName?.charAt(0) && userData?.lastName?.charAt(0)) &&
                      <a className='flex items-center md:gap-[15px] gap-[10px]' href={`/${storeAssigned()}`}>
                        <span className='logoutbuTton'>
                          <button className='cursor-pointer leading-normal futura_font font-normal  text-white text-lg border-2 border-[#ffffff] rounded-full py-1 px-5' onClick={handleLogout}>Logout</button>
                        </span>
                        <span className='userName text-white'>Welcome </span>
                        <span className='userImage'>
                          {`${userData?.firstName?.charAt(0)}${userData?.lastName?.charAt(0)}`}
                        </span>

                      </a>
                    }
                  </>
                )}
              </div>
            </div>

            <div className='mobile-toggle md:hidden block'>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                <MenuIcon />
              </IconButton>
            </div>

          </div>

          <nav>

            <ul className='flex mt-[10px] items-center gap-[18px]'>
              {/* {
                isUserLogin && (
                  <li>
                    <a href={`/${storeAssigned()}/products`} className='text-[#8dfa00] md:text-[22px] text-18px' >Shop Now</a>
                  </li>
                )
              } */}
              {
                !isUserLogin && (
                  <li>
                    <a href={`/${storeAssigned()}/main-enrollment`} className='text-[#8dfa00] md:text-[22px] text-18px'>Join Us</a>
                  </li>
                )
              }
            </ul>


          </nav>
        </div>
      </header>
      }
    </>
  );
};
export default Header;